const info = [
  {
    key: 1,
    title: "",
    content: "",
    imageSrc: "./Images/MePhoto.png",
    imageAlt: "Riley Nielson",
  },
  {
    key: 2,
    title: "Full Stack Deployment",
    content:"",
    imageSrc: "",
    imageAlt: "",
  },
  {
    key: 3,
    title: "User Focused Solutions",
    content: "",
    imageSrc: "",
    imageAlt: "",
  },
  {
    key: 4,
    title: "Can-Do Attitude",
    content:"",
    imageSrc: "",
    imageAlt: "",
  },
  {
    key: 5,
    title: "Connect!",
    content: "Email: Rileyanielson@gmail.com",
    imageSrc: "",
    imageAlt: "",
  },
];

export default info;
